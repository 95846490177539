import React from 'react';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatMap from 'lodash/fp/flatMap';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import uniq from 'lodash/fp/uniq';

import SubscriptionIcon from '@mui/icons-material/Autorenew';
import DispatchIcon from '@mui/icons-material/CallSplit';
import ProductionIcon from '@mui/icons-material/Colorize';
import FastConsultationIcon from '@mui/icons-material/FlashOn';
import ExportIcon from '@mui/icons-material/GetApp';
import BufferIcon from '@mui/icons-material/GridOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/InvertColors';
import CustomerIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import GiftIcon from '@mui/icons-material/Redeem';
import ScienceSharpIcon from '@mui/icons-material/ScienceSharp';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import styled from '@mui/material/styles/styled';
import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import navigationPermissionsForGroup from 'utils/teammatesAccess/navigationPermissionsForGroup';
import {
  AUTO_DISPATCH,
  BUFFER_SPACES_LIST,
  CUSTOMER_LIST,
  DATA_PORTAL,
  EXPORTS,
  FAST_CONSULTATION,
  FEEDBACK_LIST,
  GIFT_LIST,
  HELP,
  MICRO_HOLD_PALLETIZE_BOXES,
  MICRO_HOLD_TESTS_AND_PALLETS,
  ORDER_LIST,
  PRINT_COLLATERALS,
  PRODUCTION_LIST,
  SUBSCRIPTION_LIST,
  TEAMMATES_LIST,
} from 'utils/teammatesAccess/teammatesPermissions';

import type { EnhancedTeammate, FetchedTeammate, MenuAccess, TeammatesError } from './types';

const StyledPalletsIcon = styled(TableChartSharpIcon)`
  transform: scale(-1, -1);
`;

const menuItems = [
  // CX
  {
    name: 'Customers',
    dataTestId: 'nav-customers',
    to: '/customers',
    icon: <CustomerIcon />,
    slug: CUSTOMER_LIST,
    group: 'CX',
  },
  {
    name: 'Orders',
    dataTestId: 'nav-orders',
    to: '/orders',
    icon: <OrderIcon />,
    slug: ORDER_LIST,
    group: 'CX',
  },
  {
    name: 'Subscriptions',
    dataTestId: 'nav-subscriptions',
    to: '/subscriptions',
    icon: <SubscriptionIcon />,
    slug: SUBSCRIPTION_LIST,
    group: 'CX',
  },
  {
    name: 'Feedbacks',
    dataTestId: 'nav-feedbacks',
    to: '/feedbacks',
    icon: <FeedbackIcon />,
    slug: FEEDBACK_LIST,
    group: 'CX',
  },
  {
    name: 'Gifts',
    dataTestId: 'nav-gifts',
    to: '/gifts',
    icon: <GiftIcon />,
    slug: GIFT_LIST,
    group: 'CX',
  },
  // Fulfillment
  {
    name: 'Production',
    dataTestId: 'nav-production',
    to: '/production',
    icon: <ProductionIcon />,
    slug: PRODUCTION_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Dispatch',
    dataTestId: 'nav-dispatch',
    to: '/production/dispatch/auto',
    icon: <DispatchIcon />,
    slug: AUTO_DISPATCH,
    group: 'Fulfillment',
  },
  {
    name: 'Buffer',
    dataTestId: 'nav-buffer',
    to: '/production/buffer-spaces',
    icon: <BufferIcon />,
    slug: BUFFER_SPACES_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Print',
    dataTestId: 'nav-print-collaterals',
    to: '/production/print-collaterals',
    icon: <PrintIcon />,
    slug: PRINT_COLLATERALS,
    group: 'Fulfillment',
  },
  // Microhold
  {
    name: 'Quality Tests',
    dataTestId: 'nav-quality-tests',
    to: '/production/quality-tests/ongoing',
    icon: <ScienceSharpIcon />,
    slug: MICRO_HOLD_TESTS_AND_PALLETS,
    group: 'Microhold',
  },
  {
    name: 'Palletize Boxes',
    dataTestId: 'nav-palletize-boxes',
    to: 'production/palletize-boxes/',
    icon: <WidgetsSharpIcon />,
    slug: MICRO_HOLD_PALLETIZE_BOXES,
    group: 'Microhold',
  },
  {
    name: 'Pallets Management',
    dataTestId: 'nav-pallets-management',
    to: 'production/pallets-management/new',
    icon: <StyledPalletsIcon />,
    slug: MICRO_HOLD_TESTS_AND_PALLETS,
    group: 'Microhold',
  },
  // Data Analysis
  {
    name: 'Exports',
    dataTestId: 'nav-exports',
    to: '/exports',
    icon: <ExportIcon />,
    slug: EXPORTS,
    group: 'Data Analysis',
  },
  {
    name: 'Data Portal',
    dataTestId: 'nav-data-portal',
    to: '/dataportal',
    icon: <InsertChartIcon />,
    slug: DATA_PORTAL,
    group: 'Data Analysis',
  },
  // Administrate
  {
    name: 'Users',
    dataTestId: 'nav-teammates',
    to: '/teammates',
    icon: <GroupsIcon />,
    slug: TEAMMATES_LIST,
    group: 'Administration',
  },
  // QA
  {
    name: 'Fast',
    dataTestId: 'nav-fast-consultation',
    to: '/fastconsultation',
    icon: <FastConsultationIcon />,
    slug: FAST_CONSULTATION,
    group: 'Administration',
  },
  // Misc
  {
    name: 'Help',
    dataTestId: 'nav-help',
    to: '/help',
    icon: <HelpIcon />,
    slug: HELP,
    group: 'Misc',
  },
];

type TeammatesSlice = RootState['teammates'];

const getTeammatesState: OmsSelector<TeammatesSlice> = state => state.teammates;

export const getError: OmsSelector<TeammatesSlice['error']> = createSelector(
  getTeammatesState,
  get('error')
);
export const getErrorKind: OmsSelector<TeammatesError['kind']> = createSelector(
  getError,
  get('kind')
);
export const getStatus: OmsSelector<TeammatesSlice['status']> = createSelector(
  getTeammatesState,
  get('status')
);
export const getTeammates: OmsSelector<EnhancedTeammate[]> = createSelector(
  getTeammatesState,
  get('teammates')
);
export const getPages: OmsSelector<TeammatesSlice['pages']> = createSelector(
  getTeammatesState,
  get('pages')
);

export const getSignedInTeammate: OmsSelector<FetchedTeammate> = createSelector(
  getTeammatesState,
  get('signedInTeammate')
);
export const getSignedInTeammateFirstName: OmsSelector<['first_name']> = createSelector(
  getSignedInTeammate,
  get('first_name')
);
export const getSignedInTeammateLastName: OmsSelector<FetchedTeammate['last_name']> =
  createSelector(getSignedInTeammate, get('last_name'));
export const getSignedInTeammateUsername: OmsSelector<FetchedTeammate['username']> = createSelector(
  getSignedInTeammate,
  get('username')
);

export const getSignedInTeammateGroups: OmsSelector<FetchedTeammate['groups']> = createSelector(
  getSignedInTeammate,
  get('groups')
);

// This function below exists to set the permissions for the updated Ops Supervisor role. Once the
// new teammates functionality is approved, this function can be removed and the Ops Supervisor
// navigation permissions can be updated in teammatesAccess/navigationPermissionsForGroup.js
// with the permissions below.

export const getMenuAccess: OmsSelector<MenuAccess> = createSelector(
  getSignedInTeammateGroups,
  groups => {
    const menuItem = flatMap(({ name }) => {
      const navigationPermissions = get(
        'navigationPermissions',
        find({ slug: name }, navigationPermissionsForGroup)
      );
      return filter(item => includes(item.slug, navigationPermissions), menuItems);
    }, groups);

    return uniq(menuItem);
  }
);
