export const CUSTOMER_LIST = 'singular_customer_list'; // /customers
export const CUSTOMER_VIEW = 'singular_customer_view'; // /customers/*

export const COUPONS_VIEW = 'singular_coupons_view'; // /coupons/*

export const ORDER_LIST = 'singular_order_list'; // /orders
export const ORDER_VIEW = 'singular_order_view'; // /order/*

export const SUBSCRIPTION_LIST = 'singular_subscription_list'; // /subscriptions
export const SUBSCRIPTION_VIEW = 'singular_subscription_view'; // /subscriptions/:pubkey/*

export const FEEDBACK_LIST = 'singular_feedback_list'; // /feedbacks

export const GIFT_LIST = 'singular_gift_list'; // /gifts/
export const GIFT_DETAIL = 'singular_gift_detail'; // /gifts/:pubkey

export const PRODUCTION_LIST = 'singular_production_list'; // /production

export const OLD_FLOW_PRODUCTION_SCREEN = 'singular_old_flow_production_screen'; // /production/*
export const NEW_FLOW_PRODUCTION_SCREEN = 'singular_new_flow_production_screen'; // /production/scan-to-produce

export const PACK_SCREEN = 'singular_pack_screen'; // /production/packing-station && /production/prepack_lab

export const AUTO_DISPATCH = 'singular_auto_dispatch'; // /production/dispatch/auto
export const MANUAL_DISPATCH = 'singular_manual_dispatch'; // /production/dispatch/manual
export const BUFFER_SPACES_LIST = 'singular_buffer_spaces_list'; // /production/buffer-spaces
export const PRINT_COLLATERALS = 'singular_print_collaterals'; // /production/print_collaterals

export const MICRO_HOLD_PALLETIZE_BOXES = 'singular_micro_hold_palletize_boxes'; // /production/palletize-boxes/
export const MICRO_HOLD_TESTS_AND_PALLETS = 'singular_micro_hold_tests_pallets'; // /production/quality-tests && /production/pallets-management && /production/palletsqrcodes

export const EXPORTS = 'singular_exports'; // /exports
export const DATA_PORTAL = 'singular_data_portal'; // /dataportal

export const SURVEY_VIEW = 'singular_survey_view'; // /surveys/:pubkey

export const TEAMMATES_LIST = 'singular_teammates_list'; // /teammates
export const TEAMMATES_DETAIL = 'singular_teammates_detail'; // /teammates/:pubkey
export const TEAMMATES_EDIT = 'singular_teammates_edit'; // /teammates/:pubkey
export const TEAMMATES_EDIT_PERMISSIONS = 'singular_teammates_edit_permissions'; // /teammates/:pubkey

export const FAST_CONSULTATION = 'singular_fast_consultation'; // /fastconsultation
export const HELP = 'singular_help'; // /help
export const HELP_STATUS_VIEWER = 'singular_help_status_viewer'; // /help/statuses
export const HELP_BOX_OBJECT_VIEWER = 'singular_help_box_object_viewer'; // /help/box-objects
export const HELP_REVIEW_AND_REFINE_VIEWER = 'singular_help_review_and_refine_viewer'; // /help/review-and-refine

export const SALON_LIST = 'singular_salon_list';
export const SALON_VIEW = 'singular_salon_view';

export const DEFECT_ORDER_DETAIL = 'singular_defect_order_detail'; // trashing items from the order detail page
